import logo from './logo.svg';
import { useState, useEffect } from 'react';
import ExcelExport from './ExcelExport';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
function Leadlist() {

  const [leads, setLeads] = useState([]);
  const [data, setData] = useState([]);

  const [isLoggedin, setIsLoggedin] = useState(false);


  const navigate = useNavigate();
  useEffect(() => {
  let loginData=  localStorage.getItem("LoginData");
    if(loginData!=null){
      setIsLoggedin(true);
    }
    else{
      setIsLoggedin(false);
      navigate('/login');
      return

    }
    const fetchContacts = async () => {
      // try {
      //   let { data: Contact, error } = await supabase
      //     .from('Contact-us')
      //     .select()
      //     .eq('ClientId', '16b79d96-52fa-4782-81f0-e24a7c822d25')
      //   console.log({ data: Contact, error });
      //   setLeads(Contact);
      // } catch (error) {
      //   console.error("Error fetching contacts:", error);
      // }
    };

    fetchContacts();
  }, []);

  console.log(leads, 'test');


  const logout = () => {
    localStorage.removeItem("LOginData");
    setIsLoggedin(false);
    navigate('/login');
  };
  return (
    <section className="team-details leads bg-color-1" style={{ paddingTop: 20, height: '100%' }}>
      <div className="auto-container">
        <div className="team-details-content">
          <div className="row align-items-center clearfix">
            <div className='col-lg-12'>
              <div className="text tcttt">
                <h3 style={{ marginBottom: 20 }}><b>Leads</b></h3>
                <div>
                  <ExcelExport data={leads} fileName="radheybiotech" />

                  <button className='theme-btn' onClickCapture={logout}>Logout</button>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center clearfix">
            {leads.map((lead) => (
              <div key={lead.id} className="col-lg-4 col-md-6 col-sm-12 content-column">
                <div className="content_block_2">
                  <div className="content-box">
                    <ul className="info clearfix">
                      <li>
                        <i className="icon-20" />
                        Name: {lead.Name}
                      </li>
                      <li>
                        <i className="icon-28" />
                        Phone: {lead.Mobile}
                      </li>
                      <li>
                        <i className="icon-52" />

                        Email: {lead.Email}
                      </li>
                      <li>
                        <i className="icon-2" />
                        Message: {lead.Query}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Leadlist;
