import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './Home';
import About from './About';
import Products from './Products';
import Contact from './Contact';
import Mission from './Misson';
import Career from './Career';
import Director from './Directors';
import Terms from './Terms';
import Privacy from './Privacy';
import Login from './Login';
import Leadlist from './Leadlist';
import Gallery from './Gallery';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <BrowserRouter>
  <Routes>
      <Route path="/" element={<Home />} /> 
      <Route path="/about" element={<About />} /> 
      <Route path="/products" element={<Products />} /> 
      <Route path="/contact" element={<Contact />} /> 
      <Route path="/mission-vission" element={<Mission />} /> 
      <Route path="/career" element={<Career />} /> 
      <Route path="/directors" element={<Director />} /> 
      <Route path="/terms" element={<Terms />} /> 
      <Route path="/privacy" element={<Privacy />} /> 
      <Route path="/login" element={<Login />} /> 
      <Route path="/leads" element={<Leadlist />} /> 
      <Route path="/gallery" element={<Gallery />} /> 
      
  </Routes>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
