import React from "react";
import { Link } from "react-router-dom";

function Header() {


  const navRef = React.useRef(null);
  const navRef2 = React.useRef(null);
  
  const onAddClick = (e) => {
    navRef.current.classList.add("active");
    navRef2.current.classList.add("active");
  };
  const onRemoveClick = (e) => {
    navRef.current.classList.remove("active");
    navRef2.current.classList.remove("active");
  };

  return (
    <div>
        
    
    {/* main header */}
    <header className="main-header style-four style-five">
      {/* header-lower */}
      <div className="header-lower">
        <div className="outer-box">
          <div className="logo-box">
          <Link to="/">  <img src="assets/images/logo.png" alt="" title className='logo' /></Link>
          </div>
          <div className="menu-area clearfix">
            {/*Mobile Navigation Toggler*/}
            <button onClick={onAddClick}>
                              <i class="fa fa-bars"></i>
                          </button>
            <nav className="main-menu navbar-expand-md navbar-light">
              <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                <ul className="navigation clearfix">
                  <li className="current"><Link to="/">Home</Link>
                
                  </li>
                  <li className="dropdown"><Link to="#">About Us</Link>
                  <ul>
                    <li><Link to="/about">Company Overview</Link></li>
                    <li><Link to="/mission-vission">Mission, Vision and Core Values
                    
                    </Link></li>
                    <li><Link to="/directors">Directors</Link></li>
                </ul>
                  
                  </li>
                  <li className=""><Link to="/products">Products</Link>
                   
                  </li>   <li className=""><Link to="/gallery">Gallery</Link>
                   
                   </li> 
                  <li className=""><Link to="/career">Career</Link>
                   
                  </li> 
                  <li className=""><Link to="/contact">Contact</Link>
                  </li> 
                 
                 
                </ul>
              </div>
            </nav>
          </div>
         
        </div>
      </div>
      {/*sticky Header*/}
      <div className="sticky-header">
        <div className="auto-container">
          <div className="outer-box clearfix">
            <div className="menu-area pull-left">
            <Link to="/"> <img src="assets/images/logo.png" alt="" title className='logo' style={{height:45}} /></Link>
            
            </div>
            <nav className="main-menu clearfix">
              <nav className="main-menu navbar-expand-md navbar-light">
              <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                <ul className="navigation clearfix">
                <li className="current"><Link to="/">Home</Link>
                
                  </li>
                  <li className="dropdown"><Link to="#">About Us</Link>
                  <ul>
                    <li><Link to="/about">Company Overview</Link></li>
                    <li><Link to="/mission-vission">Mission, Vision and Core Values
                    
                    </Link></li>
                    <li><Link to="/directors">Directors</Link></li>
                </ul>
                  
                  </li>
                  <li className=""><Link to="/products">Products</Link>
                   
                  </li> 
                  <li className=""><Link to="/gallery">Gallery</Link>
                   
                  </li> 
                  <li className=""><Link to="/career">Career</Link>
                   
                  </li> 
                  <li className=""><Link to="/contact">Contact</Link>
                  </li> 
                 
                 
                 
                </ul>
              </div>
            </nav>
              </nav>
          </div>
        </div>
      </div>
    </header>
    {/* main-header end */}
    {/* Mobile Menu  */}
    <div className="mobile-menu" ref={navRef}>
      <div className="menu-backdrop"/>
      <div className="close-btn" ref={navRef2}  onClick={onRemoveClick}><i className="fas fa-times" /></div>
      <nav className="menu-box">
        <div className="nav-logo"> <Link to="/"><img src="assets/images/logo.png" alt="" title className='logo' /></Link></div>
        <div className="menu-outer">     
              <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                <ul className="navigation clearfix">
                  <li className="current"><Link to="/">Home</Link>
                
                  </li>
                
                  <li><Link to="/about">Company Overview</Link></li>
                    <li><Link to="/mission-vission">Mission, Vision and Core Values
                    
                    </Link></li>
                    <li><Link to="/directors">Directors</Link></li>
                  <li className=""><Link to="/products">Products</Link>
                   
                  </li> <li className=""><Link to="/gallery">Gallery</Link>
                   
                   </li> 
                  <li className=""><Link to="/career">Career</Link>
                   
                  </li> 
                  <li className=""><Link to="/contact">Contact</Link>
                  </li> 
                 
                 
                </ul>
              </div>
            </div>
      
      
      </nav>
    </div>
    {/* End Mobile Menu */}
    </div>
  );
}

export default Header;
