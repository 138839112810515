import logo from './logo.svg';
import './App.css';
import Footer from "./Common/Footer";
import Header from "./Common/Header";

function Terms() {
  return (
    <>
     <div className="boxed_wrapper about-page">
     <div><Header/></div>

    <section
    className="page-title centred"
    style={{ backgroundImage: "url(assets/images/background/page-title.jpg)" }}
  >
    <div className="auto-container">
      <div className="content-box">
        <div
          className="shape"
          style={{ backgroundImage: "url(assets/images/shape/shape-63.png)" }}
        />
        <div className="title">
          <h1>Terms and Conditions

          </h1>
        </div>
      
      </div>
    </div>
  </section>
  {/* End Page Title */}
  {/* sidebar-page-container */}
  <section className="sidebar-page-container blog-details sec-pad-2">
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 content-side">
          <div className="blog-details-content">
           
            <div className="content-one">
             
              <div className="text">
          
              <h5><b>Introduction</b></h5>
              <p>Welcome to Radhey Biotech Private Limited, Udaipur website. By accessing and using our website, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use our website.
              </p>

              <h5><b>Definitions</b></h5>


                <ul className="list-item clearfix">
                  <li><b>Company: </b>Radhey Biotech Private Limited, Udaipur
                  </li>
                  <li><b>Website:  </b>Refers to Radhey Biotech Private Limited, Udaipur website.
                  </li>
                  <li><b>User: </b> Any individual or entity that accesses or uses the website.
                  </li>
                </ul>

                <h5><b>Use of Website</b></h5>
              <p>Users agree to use the website only for lawful purposes and in accordance with these terms and conditions. Users shall not use the website in any way that violates applicable laws or regulations.
              </p>


              
              <h5><b>Intellectual Property Rights
              </b></h5>
              <p>The content, trademarks, logos, and other intellectual property on the website are owned by or licensed to Radhey Biotech Private Limited, Udaipur. Users agree not to reproduce, distribute, modify, or create derivative works of any content from the website without prior written consent from Radhey Biotech Private Limited, Udaipur.
              </p>
 
              <h5><b>Information Accuracy</b></h5>
              <p>While Radhey Biotech Private Limited, Udaipur strives to provide accurate and up-to-date information on the website, we do not warrant the completeness, reliability, or accuracy of this information. Users acknowledge that any reliance on such information is at their own risk.
              </p>
              <h5><b>Disclaimer of Warranties
              </b></h5>
              <p>The website and its content are provided on an "as is" and "as available" basis. Radhey Biotech Private Limited, Udaipur makes no representations or warranties of any kind, express or implied, regarding the operation or availability of the website or the accuracy, completeness, or reliability of any information on the website.
              </p>


              <h5><b>Limitation of Liability

              </b></h5>
              <p>In no event shall Radhey Biotech Private Limited, Udaipur be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, arising out of or in connection with the use of or inability to use the website or its content.
              </p>

              <h5><b>Indemnification
              </b></h5>
              <p>Users agree to indemnify, defend, and hold harmless Radhey Biotech Private Limited, Udaipur, its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, damages, losses, liabilities, costs, and expenses  arising out of or related to the User's use of the website or violation of these terms and conditions.
              </p>


              <h5><b>Links to Third-Party Websites</b></h5>
              <p>The website may contain links to third-party websites or resources. These links are provided for convenience only, and Radhey Biotech Private Limited, Udaipur does not endorse or assume any responsibility for the content, products, services, or privacy practices of such websites.
              </p>

<h5><b>Governing Law and Jurisdiction
</b></h5>
<p>These terms and conditions shall be governed by and construed in accordance with the laws of Rajasthan, without regard to its conflict of laws principles. Any disputes arising under or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts located in Rajasthan.

</p>

<h5><b>Changes to Terms and Conditions

</b></h5>
<p>Radhey Biotech Private Limited, Udaipur reserves the right to modify these terms and conditions at any time. Users are responsible for regularly reviewing these terms and conditions. Continued use of the website after any such changes shall constitute the User's consent to such changes.
</p>

<h5><b>Contact Information
</b></h5>
<p>For any questions or concerns regarding these terms and conditions, please contact us at  IT@RadheyBiotech.com.
</p>








              </div>
            </div>
         
          </div>
        </div>
       
      </div>
    </div>
  </section>

 <div><Footer /></div>
  </div>
  </>
  );
}

export default Terms;
