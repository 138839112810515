import React from "react";
import Footer from "./Common/Footer";
import Header from "./Common/Header";

function Gallery() {
  return (
    <div className="boxed_wrapper about-page" >
   <div><Header/></div>
   <section
      className="page-title centred"
      style={{ backgroundImage: "url(assets/images/background/page-title.jpg)" }}
    >
      <div className="auto-container">
        <div className="content-box">
          <div
            className="shape"
            style={{ backgroundImage: "url(assets/images/shape/shape-63.png)" }}
          />
          <div className="title">
            <h1>Gallery</h1>
          </div>
        
        </div>
      </div>
    </section>
    {/* End Page Title */}
    <section className="gallery-page-section centred">
        <div className="outer-container">
          <div className="sortable-masonry">
          
            <div className="items-container row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all cosmetic agroscience laboratory">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p1.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p1.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience cosmetic medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p2.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p2.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all cosmetic laboratory">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p3.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p3.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience laboratory medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p4.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p4.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all cosmetic laboratory">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p5.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p5.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p6.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p6.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all cosmetic laboratory">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p7.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p7.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p8.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p8.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p9.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p9.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p10.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p10.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p11.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p11.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p12.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p12.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p13.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p13.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p14.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p14.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p15.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p15.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p17.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p17.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all agroscience medical">
                <div className="project-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src="assets/images/gallery/p16.jpg" alt="" /></figure>
                    <div className="content-box">
                      <div className="text">
                        <div className="view-btn"><a href="assets/images/gallery/p16.jpg" className="lightbox-image" data-fancybox="gallery"><i className="icon-49" /></a></div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           










            </div>
           
          </div>
        </div>
      </section>

<div><Footer /></div>
 </div>
 
  );
}

export default Gallery;
