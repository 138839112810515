import Footer from "./Common/Footer";
import Header from "./Common/Header";

function About() {
  return (
    <div className="boxed_wrapper about-page">
   <div><Header/></div>

    <section
      className="page-title centred"
      style={{ backgroundImage: "url(assets/images/background/page-title.jpg)" }}
    >
      <div className="auto-container">
        <div className="content-box">
          <div
            className="shape"
            style={{ backgroundImage: "url(assets/images/shape/shape-63.png)" }}
          />
          <div className="title">
            <h1>About Radhey Biotech</h1>
          </div>
        
        </div>
      </div>
    </section>
    {/* End Page Title */}
    {/* about-style-four */}
    <section className="about-style-four sec-pad">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            <div className="image_block_5">
              <div className="image-box">
                <div className="shape">
                  <div
                    className="shape-3"
                    style={{
                      backgroundImage: "url(assets/images/shape/shape-40.png)"
                    }}
                  />
                  <div
                    className="shape-4"
                    style={{
                      backgroundImage: "url(assets/images/shape/shape-40.png)"
                    }}
                  />
                  <div
                    className="shape-5"
                    style={{
                      backgroundImage: "url(assets/images/shape/shape-41.png)"
                    }}
                  />
                </div>
                <figure className="image image-1 paroller">
                  <img src="assets/images/resource/about-1.jpg" alt="" />
                </figure>
                {/* <figure className="image image-2 paroller-2">
                  <img src="assets/images/resource/about-2.jpg" alt="" />
                </figure> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            <div className="content_block_1">
              <div className="content-box ml-70">
                <div className="sec-title">
                 
                  <h2>About<br/> Radhey Biotech</h2>
                </div>
                <div className="bold-text">
                  <p>Welcome to Radhey Biotech Private Limited, a dynamic leader in the production of Active
Pharmaceutical Ingredients (APIs) for the global pharmaceutical industry. Founded with a vision
to drive innovation and excellence in API manufacturing, we specialize in delivering highquality, cost-effective ingredients that form the backbone of pharmaceutical formulations
worldwide..</p>
                </div>
              
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* about-style-four end */}

    

     {/* service-style-two */}
     <section className="service-style-two sec-pad centred">
      <div className="auto-container">
      
        <div className="row clearfix">
       
          <div className="col-lg-4 col-md-6 col-sm-12 service-block">
            <div
              className="service-block-two wow fadeInUp animated"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="inner-box">
              
                <div className="lower-content">
                  <div className="icon-box">
                    <i className="icon-40" />
                  </div>
                  <h4>Innovative Solutions</h4>
                  <p>
                  Driven by a passion for innovation, we continuously invest in research and development to
expand our portfolio of APIs. Our robust R&D capabilities enable us to develop novel processes,
improve existing formulations, and anticipate market trends, thereby meeting the evolving needs
of our customers.

                  </p>
                
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 service-block">
            <div
              className="service-block-two wow fadeInUp animated"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="inner-box">
              
                <div className="lower-content">
                  <div className="icon-box">
                    <i className="icon-41" />
                  </div>
                  <h4>Global Reach, Local Expertise   </h4>
                  <p>
                  Head office in Gurgaon, Radhey Biotech Private Limited operates globally with a strong focus
on local expertise. We collaborate closely with pharmaceutical companies, contract
manufacturers, and research institutions worldwide to foster partnerships that drive mutual
growth and success.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 service-block">
            <div
              className="service-block-two wow fadeInUp animated"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="inner-box">
              
                <div className="lower-content">
                  <div className="icon-box">
                    <i className="icon-41" />
                  </div>
                  <h4>Sustainability and Responsibility
                  </h4>
                  <p>
                  We are committed to sustainability in every aspect of our operations. Through efficient
manufacturing processes, responsible sourcing of raw materials, and environmental stewardship,
we strive to minimize our ecological footprint and contribute positively to the communities
where we operate. </p>
                  
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6 col-sm-12 service-block">
            <div
              className="service-block-two wow fadeInUp animated"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="inner-box">
              
                <div className="lower-content">
                  <div className="icon-box">
                    <i className="icon-41" />
                  </div>
                  <h4>Customer-Centric Approach</h4>
                  <p>
                  At Radhey Biotech Private Limited, our customers are our priority. We are dedicated to building
long-term relationships based on trust, reliability, and transparency. Our customer-centric
approach ensures responsive service, timely delivery, and tailored solutions that add value to our
partners’ businesses.   </p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 service-block">
            <div
              className="service-block-two wow fadeInUp animated"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="inner-box">
              
                <div className="lower-content">
                  <div className="icon-box">
                    <i className="icon-41" />
                  </div>
                  <h4>Join Us in Shaping the Future of Healthcare

                  </h4>
                  <p>
                  Thank you for choosing Radhey Biotech Private Limited as your trusted partner in API
manufacturing. Explore how our commitment to innovation, quality, and reliability can
contribute to your success in the pharmaceutical industry. Together, let’s shape the future of
healthcare through groundbreaking API solutions. </p>
                  
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 service-block">
            <div
              className="service-block-two wow fadeInUp animated"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <div className="inner-box">
              
                <div className="lower-content">
                  <div className="icon-box">
                    <i className="icon-39" />
                  </div>
                  <h4>
                  Our Commitment to Quality
 </h4>
                  <p>
                  At Radhey Biotech Private Limited, quality is our cornerstone. We adhere strictly to
international quality standards and regulatory requirements to ensure the safety, efficacy, and
consistency of our APIs. Our state-of-the-art manufacturing facility is equipped with advanced
technology and operated by a skilled team of professionals dedicated to upholding the highest
standards at every stage of production.
                  </p>
                 
                </div>
              </div>
            </div>
          </div>

        </div>
      
      </div>
    </section>
    {/* service-style-two end */}
   
  {/* awards-section */}
  <section className="awards-section sec-pad-2">
    <div className="shape-layer">
      <div
        className="shape-1 rotate-me"
        style={{ backgroundImage: "url(assets/images/shape/shape-58.png)" }}
      />
      <div className="shape-2" />
    </div>
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 title-column">
          <div className="sec-title">
            
            <h2>Research and Development (R&D)
            </h2>
            <p>
            At Radhey Biotech Private Limited, our Research and Development (R&D) team is dedicated to
pioneering innovative solutions in Active Pharmaceutical Ingredient (API) development. We
combine cutting-edge science with a deep understanding of market needs to create high-quality
APIs that meet the stringent requirements of the global pharmaceutical industry.
            </p>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 inner-column">
          <div className="inner-box">
            <div className="single-item">
              <h3>
              <div className="icon-box">
                    <i className="icon-40" />
                  </div>
              </h3>
              <h4>Innovative Processes</h4>
              <p>We leverage advanced technologies and methodologies to
              develop efficient and sustainable processes for API synthesis and purification.</p>
            </div>
            <div className="single-item">
              <h3>
              <div className="icon-box">
                    <i className="icon-38" />
                  </div>
              </h3>
              <h4>Therapeutic Expertise</h4>
              <p>Our R&D efforts span various therapeutic categories, ensuring
              we address diverse healthcare challenges with tailored API solutions.</p>
            </div>
            <div className="single-item">
              <h3>
              <div className="icon-box">
                    <i className="icon-35" />
                  </div>
              </h3>
              <h4>Pipeline Development</h4>
              <p>We maintain a robust pipeline of API candidates, from earlystage development through to commercialization, ensuring a continuous stream of new
              products to meet market demands.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* awards-section end */}
{/* education-section */}
  <section className="education-section sec-pad bg-color-1 centred">
    <div className="pattern-layer">
      <div
        className="pattern-1 wow zoomIn animated"
        data-wow-delay="00ms"
        data-wow-duration="1500ms"
        style={{ backgroundImage: "url(assets/images/shape/shape-71.png)" }}
      />
      <div
        className="pattern-2 wow zoomIn animated"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
        style={{ backgroundImage: "url(assets/images/shape/shape-72.png)" }}
      />
    </div>
    <div className="auto-container">
      <div className="sec-title centred">
      
        <h2 style={{marginBottom:20}}>Manufacturing Unit</h2>
        <p>Located in Udaipur, our state-of-the-art manufacturing facility is designed to meet global
regulatory standards and accommodate large-scale production of APIs. Equipped with advanced
equipment and operated by a skilled workforce, our facility ensures the consistent and reliable
supply of high-quality APIs to our customers worldwide.</p>
      </div>
      <div className="border-layout" />
      <div className="row clearfix">
        <div className="col-lg-4 col-md-6 col-sm-12 education-block">
          <div className="education-block-one">
            <div className="inner-box">
              <div className="dot-box" />
              
              <h4>Advanced Infrastructure</h4>
              <p>Our facility features modern infrastructure and automated
systems to optimize production efficiency and minimize environmental impact.
</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 education-block">
          <div className="education-block-one">
            <div className="inner-box">
              <div className="dot-box" />
             
              <h4>Capacity and Scalability</h4>
              <p>We have the flexibility to scale production according to
              market demand while maintaining stringent quality controls.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 education-block">
          <div className="education-block-one">
            <div className="inner-box">
              <div className="dot-box" />
             
              <h4>Compliance and Safety</h4>
              <p>We adhere strictly to international regulatory requirements
(e.g., ICH, WHO, GMP) and implement rigorous safety protocols to ensure product
integrity and workplace safety</p>
            </div>
          </div>
        </div>
     
      </div>
    </div>
  </section>
  {/* education-section end */}


  <section className="chooseus-section sec-pad">

  <div
    className="bg-layer"
    style={{ backgroundImage: "url(assets/images/background/chooseus-1.jpg)" }}
  />
  <div className="auto-container">
    <div className="row clearfix">
      <div className="col-lg-5 col-md-12 col-sm-12 content-column">
        <div className="content_block_6">
          <div className="content-box">
            <div className="sec-title">
              <h2 style={{marginBottom:20}}>Quality Control and Quality Assurance</h2>
              <p>Quality is paramount at Radhey Biotech Private Limited. Our dedicated Quality Control (QC)
and Quality Assurance (QA) teams work in tandem to uphold the highest standards of product
quality and safety throughout the manufacturing process.</p>
            </div>
            <div className="inner-box">
            
              <div className="single-item">
                <div className="icon-box">
                  <i className="icon-36" />
                </div>
                <h4>Comprehensive Testing</h4>
                <p>We conduct thorough testing and analysis at every stage of
                production to verify the identity, purity, and potency of our APIs</p>
              </div>
              <div className="single-item">
                <div className="icon-box">
                  <i className="icon-37" />
                </div>
                <h4>Continuous Improvement</h4>
                <p>Through ongoing monitoring and data-driven insights, we
continuously improve our processes and procedures to enhance product quality and
operational efficiency.
</p>
              </div>
              <div className="single-item">
                <div className="icon-box">
                  <i className="icon-38" />
                </div>
                <h4>Regulatory Compliance</h4>
                <p>Our QC/QA practices are aligned with global regulatory
                requirements, ensuring compliance and readiness for regulatory inspections and audits.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

   {/* service-style-two */}
   <section className="service-style-two sec-pad centred" style={{marginTop:100}}>
      <div className="auto-container">
      
        <div className="row clearfix">
       
          <div className="col-lg-6 col-md-6 col-sm-12 service-block">
            <div
              className="service-block-two wow fadeInUp animated"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="inner-box">
              
                <div className="lower-content">
                  <div className="icon-box">
                    <i className="icon-40" />
                  </div>
                  <h4>Commitment to Sustainability</h4>
                  <p>At Radhey Biotech Private Limited, we are committed to sustainability and environmental
responsibility. We integrate eco-friendly practices into our manufacturing processes, optimize
resource utilization, and strive to minimize waste generation and energy consumption.

                  </p>
                
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 service-block">
            <div
              className="service-block-two wow fadeInUp animated"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="inner-box">
              
                <div className="lower-content">
                  <div className="icon-box">
                    <i className="icon-41" />
                  </div>
                  <h4>Customer-Centric Approach</h4>
                  <p>
                  We are dedicated to building strong, collaborative partnerships with our customers. Our
customer-centric approach ensures responsiveness, flexibility, and transparency in our
interactions, allowing us to deliver tailored API solutions that meet our partners' specific needs
and expectations.</p>
                  
                </div>
              </div>
            </div>
          </div>
        

      

        </div>
      
      </div>
    </section>
    {/* service-style-two end */}
   

 <div><Footer /></div>
  </div>
  
  );
}

export default About;
