
import React, { useState } from "react";
import Footer from "./Common/Footer";
import Header from "./Common/Header";
import CookieConsent, { Cookies } from "react-cookie-consent";

function Home() {


  //console.log(supabase, "supabase")
  function isValidEmail(email) {
    // Basic email validation regex
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const [Phonenumber, setPhonenumber] = useState();
  const [Email, setEmail] = useState();
  const [msg, setmsg] = useState();
  const [UserName, setUserName] = useState();

  async function submitustmDataEmailNew() {
    // Validate required fields
    if (UserName === '' || Phonenumber === '' || Email === '' || msg === '') {
      alert('Please fill out all required fields.');
      return;
    }
    // Validate email format (basic check)
    else if (!isValidEmail(Email)) {
      alert('Please enter a valid email address.');
      return;
    }
    else {
      const data = {
        ClientId: "16b79d96-52fa-4782-81f0-e24a7c822d25",
        Query: msg,
        Name: UserName,
        Email: Email,
        Mobile: Phonenumber,
        // Subject: msgsubject
      };
      const headers = {
        "Content-Type": "application/json",
        "clientid": "16b79d96-52fa-4782-81f0-e24a7c822d25",
        "page-name": "Contact_Us",
        //"SendToEmail": "Chandrakant111420@gmail.com",
        "EmailSubject": "Contact Form Submission",
      };
      try {
        const response = await fetch(
          "https://crmleads.erpthemes.com/savecrmlead",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        console.log(response);
        const result = await response.json();
        if (result) {
          // alert(
          //   "Thank you for reaching out to us; we will get back to you shortly. Lead id: " +
          //   result.data[0].ID
          // );
          alert(result);
          window.scrollTo(0, 0);
          //window.location.reload();
          setPhonenumber('""');
          setEmail('');
          setmsg('');
          setUserName('');
        } else {
          console.log(result)
          alert("Please try again later.");
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    }
  }
  const submitCustmDataEmailNew = async () => {

    // Validate required fields
    if (UserName === '' || Phonenumber === '' || Email === '' || msg === '') {
      alert('Please fill out all required fields.');
      return;
    }
    // Validate email format (basic check)
    else if (!isValidEmail(Email)) {
      alert('Please enter a valid email address.');
      return;
    }
    else {
      const data = {
        // ClientId: "16b79d96-52fa-4782-81f0-e24a7c822d25",
        query: msg,
        name: UserName,
        email: Email,
        mobile: Phonenumber,
        domain: "radheybiotech.com"
      };
      const headers = {
        "Content-Type": "application/json",
        "authorization": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzI5Y2NiMzFmZGU4NTM3YWI3OGNmZGIiLCJzZXNzaW9uIjoiNjcyOWNjYjMxZmRlODUzN2FiNzhjZmRkIiwibmFtZSI6IlJhZGhleUJpb1RlY2giLCJlbWFpbCI6ImluZm9AcmFkaGV5YmlvdGVjaC5jb20iLCJ1c2VyVHlwZSI6InVzZXIiLCJ1c2VySWQiOiI2NzI5Y2NiMzFmZGU4NTM3YWI3OGNmZGIiLCJpYXQiOjE3MzA3OTI2MjcsImV4cCI6MTg4ODU4MDYyN30.HBBfuKqyzhn4MoBDOJGmPTpFHL6IfsBrXBahnq5jStZe3rHJHdPgzbI1uzgAWVt65_WndH-dWd-bMRcd5ivEPGtbPA59ehvNuzLilVc2H18S-xmgSVl7gknM4B330ZIQmfPjbMJWr0WaPQBH0iEQ0vLMSuYtVjPSzTVelyrB0Sz-Zw-0L6-TURGhctaC27SobQ3HriZ8Jmq1X4mUzBlzAtbzsSVX_xUoVc36or8YPyu4iOuB4RezYG-ywNcxQS-MY5wvJBIVaZqL3_NqdYTUSymXMTyih3Dj-5Zh-Bq00Q0qWtGlT_sMkdVfcYH_GklqPfKl8-mfaJfUqo424MWjvQ",
        "Accept": "application/json",
        "Accept-Language": "en",
      };
      try {
        const response = await fetch(
          "https://projects.erpthemes.com/api/dynamic/addRecordsDynamic?tempID=24&tempName=radheybiotech_contact_us",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        console.log(response);
        const result = await response.json();
        if (result) {
          // alert(
          //   "Thank you for reaching out to us; we will get back to you shortly. Lead id: " +
          //   result.data[0].ID
          // );
          alert(result.resp.message);
          window.scrollTo(0, 0);
          //window.location.reload();
          setPhonenumber('');
          setEmail('');
          setmsg('');
          setUserName('');
        } else {
          console.log(result)
          alert("Please try again later.");
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    }
  };
  return (

    <div className="boxed_wrapper">
      {/*    
    <div className="loader-wrap">
      <div className="preloader">
        <div className="preloader-close">Preloader Close</div>
        <div id="handle-preloader" className="handle-preloader">
          <div className="animation-preloader">
            <div className="spinner" />
            <div className="txt-loading">
              <span data-text-preloader="l" className="letters-loading">
                l
              </span>
              <span data-text-preloader="a" className="letters-loading">
                a
              </span>
              <span data-text-preloader="b" className="letters-loading">
                b
              </span>
              <span data-text-preloader="o" className="letters-loading">
                o
              </span>
              <span data-text-preloader="r" className="letters-loading">
                r
              </span>
              <span data-text-preloader="e" className="letters-loading">
                e
              </span>
              <span data-text-preloader="x" className="letters-loading">
                x
              </span>
            </div>
          </div>  
        </div>
      </div>
    </div> */}
      <div><Header /></div>
      {/* banner-section */}
      <section className="banner-section style-four style-five">
        <div className="pattern-layer">
          <div className="pattern-1 rotate-me" style={{ backgroundImage: 'url(assets/images/shape/shape-52.png)' }} />
          <div className="pattern-2 wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms" style={{ backgroundImage: 'url(assets/images/shape/shape-53.png)' }} />
          <div className="pattern-3 wow slideInRight animated animated" data-wow-delay="00ms" data-wow-duration="1500ms" style={{ backgroundImage: 'url(assets/images/shape/shape-54.png)' }} />
          {/* <div className="pattern-4" style={{backgroundImage: 'url(assets/images/shape/shape-68.png)'}} /> */}
        </div>

        <div className="banner-txt">
          <img src="assets/images/banner/banner.png" alt="" />
          {/* 
<h2>Excellence in Research & Manufacturing for Advanced FGI (Finished
  Goods Intermediates), API (Active Pharmaceutical Ingredients).</h2> */}

        </div>

        {/* <div className="banner-carousel owl-theme owl-carousel owl-dots-none">
        <div className="slide-item">
          <div className="auto-container">
            <div className="row align-items-center clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content-box">
                  <h2>We’re Responsible for The Safety of Cosmetics Testing</h2>
                  <p>Excepteur sint occaecat cupidatat non proident sunt <br />culpa qui officia deserunt mollit.</p>
                  <div className="btn-box">
                    <a href="research.html" className="theme-btn-one">Our Services</a>
                    <a href="index-5.html" className="banner-btn">Discover</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image-box">
                  <div className="shape">
                    <div className="shape-3" style={{backgroundImage: 'url(assets/images/shape/shape-52.png)'}} />
                    <div className="shape-4" style={{backgroundImage: 'url(assets/images/shape/shape-52.png)'}} />
                  </div>
                  <figure className="image"><img src="assets/images/banner/banner-img-1.jpg" alt="" /></figure>
                  <div className="admin-box">
                    <figure className="admin-thumb"><img src="assets/images/banner/admin-1.png" alt="" /></figure>
                    <h4>600+ Different Tests</h4>
                    <p>Experienced professionals</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-item">
          <div className="auto-container">
            <div className="row align-items-center clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content-box">
                  <h2>We’re Responsible for The Safety of Cosmetics Testing</h2>
                  <p>Excepteur sint occaecat cupidatat non proident sunt <br />culpa qui officia deserunt mollit.</p>
                  <div className="btn-box">
                    <a href="research.html" className="theme-btn-one">Our Services</a>
                    <a href="index-5.html" className="banner-btn">Discover</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image-box">
                  <div className="shape">
                    <div className="shape-3" style={{backgroundImage: 'url(assets/images/shape/shape-52.png)'}} />
                    <div className="shape-4" style={{backgroundImage: 'url(assets/images/shape/shape-52.png)'}} />
                  </div>
                  <figure className="image"><img src="assets/images/banner/banner-img-2.jpg" alt="" /></figure>
                  <div className="admin-box">
                    <figure className="admin-thumb"><img src="assets/images/banner/admin-1.png" alt="" /></figure>
                    <h4>600+ Different Tests</h4>
                    <p>Experienced professionals</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-item">
          <div className="auto-container">
            <div className="row align-items-center clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content-box">
                  <h2>We’re Responsible for The Safety of Cosmetics Testing</h2>
                  <p>Excepteur sint occaecat cupidatat non proident sunt <br />culpa qui officia deserunt mollit.</p>
                  <div className="btn-box">
                    <a href="research.html" className="theme-btn-one">Our Services</a>
                    <a href="index-5.html" className="banner-btn">Discover</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image-box">
                  <div className="shape">
                    <div className="shape-3" style={{backgroundImage: 'url(assets/images/shape/shape-52.png)'}} />
                    <div className="shape-4" style={{backgroundImage: 'url(assets/images/shape/shape-52.png)'}} />
                  </div>
                  <figure className="image"><img src="assets/images/banner/banner-img-3.jpg" alt="" /></figure>
                  <div className="admin-box">
                    <figure className="admin-thumb"><img src="assets/images/banner/admin-1.png" alt="" /></figure>
                    <h4>600+ Different Tests</h4>
                    <p>Experienced professionals</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </section>
      {/* banner-section end */}


      {/* about-style-two */}
      <section className="sec-pad">
        <div className="auto-container">
          <div className="row align-items-center clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_8">
                <div className="image-box">
                  <div className="shape">
                    <div className="shape-1 rotate-me" style={{ backgroundImage: 'url(assets/images/shape/shape-56.png)' }} />
                    <div className="shape-2" style={{ backgroundImage: 'url(assets/images/shape/shape-57.png)' }} />
                    <div className="shape-3" style={{ backgroundImage: 'url(assets/images/shape/shape-57.png)' }} />
                  </div>
                  <figure className="image image-1"><img src="assets/images/resource/about-3.jpg" alt="" /></figure>
                  <figure className="image image-2 paroller"><img src="assets/images/resource/about-4.jpg" alt="" /></figure>
                  <figure className="image image-3 paroller-2"><img src="assets/images/resource/future-2.jpg" alt="" /></figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_1">
                <div className="content-box">
                  <div className="sec-title">

                    <h2>About Radhey Biotech</h2>
                  </div>
                  <div className="bold-text">
                    <p>Established in 2023, Radhey Bio Tech Private Limited, one of India’s
                      leading pharmaceutical companies has grown leaps and bounds to emerge
                      as a well reputed organization for research and manufacturing of FGI &
                      API</p>
                  </div>
                  {/*                 
                <div className="inner-box">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                      <div className="single-item">
                        <div className="icon-box"><i className="icon-34" /></div>
                        <h2>320+</h2>
                        <h5>Wining Awards</h5>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                      <div className="single-item">
                        <div className="icon-box"><i className="icon-35" /></div>
                        <h2>10k+</h2>
                        <h5>Test Completed</h5>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-style-two end */}
      {/* service-section */}
      <section className="service-section alternat-2">
        <div className="pattern-layer">
          {/* <div className="pattern-3" style={{backgroundImage: 'url(assets/images/shape/shape-55.png)'}} /> */}
          <div className="pattern-1 wow zoomIn animated" data-wow-delay="00ms" data-wow-duration="1500ms" style={{ backgroundImage: 'url(assets/images/shape/shape-3.png)', opacity: '0.1' }} />
          <div className="pattern-2  wow zoomIn animated" data-wow-delay="300ms" data-wow-duration="1500ms" style={{ backgroundImage: 'url(assets/images/shape/shape-4.png)', opacity: '0.1' }} />
        </div>
        <div className="auto-container">
          <div className="sec-title centred">
            <h2>Solutions</h2>
            <p className="para">We offer a wide range of diversified products and state-of-the-art facilities
              that include flexible, cost-effective Technology and manufacturing solutions
              with value-added services.</p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 service-block">
              <div className="service-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="inner-box">
                  <div className="icon-box"><i className="icon-5" /></div>
                  <h4>MANUFACTURING UNITS
                  </h4>
                  <p>Radhey Bio. Tech Private Limited. has built meaningful relationships and has collaborated with
                    leading FGI (Finished Goods Intermediates), API (Active Pharmaceutical Ingredients), Contract
                    Manufacturing Organizations (CMOs), and various other pharmaceutical conglomerates for support to
                    commercialize our technologies in certain specialized products.
                  </p>

                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 service-block">
              <div className="service-block-one wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                <div className="inner-box">
                  <div className="icon-box"><i className="icon-7" /></div>
                  <h4>Development QA</h4>
                  <p>All developmental activities are conducted as per global regulatory guidelines through in-house
                    quality-controlled designs making use of Quality by Design (QbD) & Design of Experiments (DoE)
                    methodologies. The research & Development Portfolios are being created for differentiated &
                    innovative ideas for life cycle management of existing Intermediate molecules.</p>

                </div>
              </div>
            </div>


            <div className="col-lg-12 col-md-12 col-sm-12 service-block">
              <div className="service-block-one wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                <div className="inner-box">
                  <div className="icon-box"><i className="icon-6" /></div>
                  <h4>RESEARCH & DEVELOPMENT
                  </h4>
                  <p>Conceptualized as a centre of excellence, we have a fully functional in-house R&D unit at Mandap,
                    Mavli (Rajasthan) in the India. Supported with a team of experienced scientists, research personnel
                    and adequately trained staff, the R&D unit has three major functions.</p>

                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 service-block">
              <div className="service-block-one wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                <div className="inner-box">
                  <div className="icon-box"><i className="icon-8" /></div>
                  <h4>API Development </h4>
                  <p>Active Pharmaceutical Ingredients (APIs) are the biologically active components of a drug that
                    produce the intended therapeutic effects. In antibiotics, APIs are responsible for inhibiting
                    bacterial growth or killing bacteria, making them crucial in treating infections.</p>

                  <p>We start by identifying bacterial targets essential for the survival and growth of bacteria.
                    These targets include enzymes and cell wall components that are crucial for bacterial
                    replication. Using advanced screening techniques, we identify promising lead compounds
                    from extensive chemical libraries. These leads exhibit potent antibacterial activity and
                    form the basis for further development. Through Structure-Activity Relationship (SAR)
                    studies, we optimize lead compounds to enhance their efficacy, minimize side effects,
                    and improve pharmacokinetic properties.</p>
                  <p>We develop robust and scalable synthetic routes to produce the API in high purity and
                    yield. This step involves meticulous planning and the selection of optimal reagents and
                    catalysts. Our team utilizes state-of-the-art analytical techniques to confirm the chemical
                    structure, purity, and stability of the API.</p>
                  <p>We scale up the manufacturing process from lab scale to commercial production,
                    ensuring that each step is efficient, reproducible, and compliant with regulatory
                    standards. Through Stringent quality control measures are in place to verify the
                    consistency, purity, and potency of each batch of API. Comply with Good Manufacturing
                    Practices (GMP) to ensure product quality and safety. Manage logistics for distributing
                    the API to formulation sites efficiently.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      {/* service-section end */}




      {/* testimonial-section 
    <section className="testimonial-section alternat-4 bg-color-1">
      <div className="bg-layer" style={{backgroundImage: 'url(assets/images/background/testimonial-3.jpg)'}} />
      <div className="pattern-layer" style={{backgroundImage: 'url(assets/images/shape/shape-59.png)'}} />
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12 offset-lg-6 inner-column">
            <div className="inner-box">
              <div className="sec-title">
                <h6>Testimonials</h6>
                <h2>What Our Patient Say?</h2>
              </div>
              <div className="single-item-carousel owl-carousel owl-theme owl-dots-none">
                <div className="content_block_3">
                  <div className="content-box">
                    <ul className="rating clearfix">
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                    </ul>
                    <div className="text">
                      <p>Lorem ipsum dolor amet consectet adipiscing sed do eiusmod tempor incidunt labore dolor magna aliqua ipsum suspen disse.</p>
                    </div>
                    <div className="author-box">
                      <figure className="author-thumb"><img src="assets/images/resource/testimonial-1.png" alt="" /></figure>
                      <h4>Robert Downey</h4>
                      <span className="designation">New York</span>
                    </div>
                  </div>
                </div>
                <div className="content_block_3">
                  <div className="content-box">
                    <ul className="rating clearfix">
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                    </ul>
                    <div className="text">
                      <p>Lorem ipsum dolor amet consectet adipiscing sed do eiusmod tempor incidunt labore dolor magna aliqua ipsum suspen disse.</p>
                    </div>
                    <div className="author-box">
                      <figure className="author-thumb"><img src="assets/images/resource/testimonial-1.png" alt="" /></figure>
                      <h4>Robert Downey</h4>
                      <span className="designation">New York</span>
                    </div>
                  </div>
                </div>
                <div className="content_block_3">
                  <div className="content-box">
                    <ul className="rating clearfix">
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                      <li><i className="icon-20" /></li>
                    </ul>
                    <div className="text">
                      <p>Lorem ipsum dolor amet consectet adipiscing sed do eiusmod tempor incidunt labore dolor magna aliqua ipsum suspen disse.</p>
                    </div>
                    <div className="author-box">
                      <figure className="author-thumb"><img src="assets/images/resource/testimonial-1.png" alt="" /></figure>
                      <h4>Robert Downey</h4>
                      <span className="designation">New York</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
 testimonial-section end */}
      <>
        {/* contact-section */}
        <section className="contact-section">

          <div className="auto-container">
            <div className="row clearfix">

              <div className="col-xl-6 col-lg-12 col-md-12">

                <div className="map-inner">
                  <div className="map-container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.4700782815644!2d77.08678737456724!3d28.49550149029197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19386d163f31%3A0x7607a237fcd132d6!2sTower%20A%2C%20DLF%20Tower%208th%20Rd%2C%20DLF%20Cyber%20City%2C%20DLF%20Phase%202%2C%20Sector%2024%2C%20Gurugram%2C%20Haryana%20122002!5e0!3m2!1sen!2sin!4v1720613065591!5m2!1sen!2sin" width="100%" height="550" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>

              </div>
              <div className="col-xl-6 col-lg-12 col-md-12 offset-xl-6">
                <div className="content_block_5">
                  <div className="content-box">
                    <h2>Contact with Us for The Better Result</h2>
                    <div
                      id="contact-form"
                      className="default-form"
                    >
                      <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="text"
                            name="fullname"
                            placeholder="Name*"
                            required
                            value={UserName}
                            onChange={(e) => {
                              setUserName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email*"
                            required
                            value={Email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Phone*"
                            required minLength={10} maxLength={10}
                            value={Phonenumber}
                            onChange={(e) => {
                              setPhonenumber(e.target.value);
                            }}
                          />
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                    value={form.subject}
                    onChange={handleForm}
                  />
                </div>*/}
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <textarea
                            name="message"
                            placeholder="Your Message"
                            value={msg}
                            onChange={(e) => {
                              setmsg(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                          <button
                            className="theme-btn-one"
                            type="submit"
                            name="submit-form"
                            onClick={submitCustmDataEmailNew}
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact-section end */}
      </>

      <div><Footer /></div>

      <CookieConsent
        enableDeclineButton
        flipButtons
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#000" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </div>
  );
}

export default Home;
