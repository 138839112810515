import logo from './logo.svg';
import './App.css';
import Footer from "./Common/Footer";
import Header from "./Common/Header";

function Director() {
  return (
    <div class="boxed_wrapper team-page">
   <div><Header/></div>
<section className="team-details bg-color-1" style={{marginTop:70, paddingBottom:90}}>
  <div className="auto-container">
    <div className="team-details-content">
      <div className="row align-items-center clearfix">
        <div className="col-lg-3 col-md-3 col-sm-12 image-column">
          <div className="image_block_2">
            <div className="image-box">
              <div className="shape">
                <div
                  className="shape-1 rotate-me"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-8.png)"
                  }}
                />
               
                <div
                  className="shape-3 rotate-me"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-10.png)"
                  }}
                />
                <div className="shape-4" />
              </div>
              <figure className="image">
                <img src="assets/images/team/rohit.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-9 col-sm-12 content-column">
          <div className="content_block_2">
            <div className="content-box">
              <div className="sec-title">
              
                <h2>Mr. Rohit Chauhan
                </h2>
                <ul className="info clearfix">
                <li>
                  <i className="icon-52" />
                  Email: <a href="mailto:rohit.chauhan@radheybiotech.com">rohit.chauhan@radheybiotech.com</a>
                </li>
              </ul>
                <p>With a deep-rooted passion for pharmaceutical innovation, I am excited to lead Radhey Biotech Private Limited in our mission to provide superior APIs. Our focus on rigorous research and advanced manufacturing techniques ensures that we deliver products of unparalleled quality and reliability. Our commitment is to drive the future of healthcare. Our mission is to innovate and excel in the pharmaceutical industry by delivering high-quality APIs that are essential to the creation of life-saving medications. Joining forces at Radhey Biotech, we aim to set new benchmarks in the API industry. Our state-of-the-art facilities and cutting-edge research initiatives are designed to meet the evolving needs of the pharmaceutical landscape. I am committed to fostering a culture of innovation and integrity, ensuring that our contributions lead to better health outcomes worldwide.
                </p>
            
              </div>
           
          
            </div>
          </div>
        </div>

        <div className='col-lg-12'>
        <p>
                Our vision is to be a trusted partner in the pharmaceutical industry, known for our unwavering dedication to quality, integrity, and customer satisfaction. We believe that collaboration is key to success. 

                </p>
                <p>We are dedicated to maintaining the highest levels of transparency and accountability in all our processes. Through strategic collaborations and a customer-centric approach, we aim to build lasting partnerships that drive success and innovation in the pharmaceutical industry.</p>
                <p>
Thank you for visiting our website. Together, we can achieve extraordinary advancements in global healthcare.
</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="team-details">
  <div className="auto-container">
    <div className="team-details-content">
      <div className="row align-items-center clearfix">
      <div className="col-lg-9 col-md-9 col-sm-12 content-column">
          <div className="content_block_2">
            <div className="content-box">
              <div className="sec-title">
              
                <h2>Mr. Saurabh Agarwal
                </h2>
                <ul className="info clearfix">
                <li>
                  <i className="icon-52" />
                  Email: <a href="mailto:saurabh@radheybiotech.com">saurabh@radheybiotech.com</a>
                </li>
              </ul>
                <p>As the Director of Radhey Biotech Private Limited, I am thrilled to introduce our new venture dedicated to the development and supply of high-quality Active Pharmaceutical Ingredients (APIs). At Radhey Biotech, we are committed to advancing healthcare by providing innovative and reliable APIs that form the backbone of effective medications.
                </p>
            <p>Our team of experts brings a wealth of experience and a passion for excellence, ensuring that every product we deliver meets the highest standards of quality and safety. We believe that our dedication to research and development, coupled with our state-of-the-art manufacturing processes, positions us as a leader in the pharmaceutical industry.</p>
              </div>
           
          
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-12 image-column">
          <div className="image_block_2">
            <div className="image-box">
              <div className="shape">
                <div
                  className="shape-1 rotate-me"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-8.png)"
                  }}
                />
              
                <div
                  className="shape-3 rotate-me"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-10.png)"
                  }}
                />
                <div className="shape-4" />
              </div>
              <figure className="image">
                <img src="assets/images/team/saurbh.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>
      

        <div className='col-lg-12'>
        <p>At the core of our mission is a commitment to improving patient outcomes and supporting our partners in their quest to develop groundbreaking therapies. We understand the critical role that APIs play in the pharmaceutical supply chain, and we are dedicated to ensuring that our products contribute to the success of our clients and the well-being of patients around the world. 
        </p>
                <p>Thank you for visiting our website. We look forward to partnering with you and making a positive impact on global healthcare together.
                </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="team-details bg-color-1" style={{marginTop:70, paddingBottom:90}}>
  <div className="auto-container">
    <div className="team-details-content">
      <div className="row align-items-center clearfix">
        <div className="col-lg-3 col-md-3 col-sm-12 image-column">
          <div className="image_block_2">
            <div className="image-box">
              <div className="shape">
                <div
                  className="shape-1 rotate-me"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-8.png)"
                  }}
                />
              
                <div
                  className="shape-3 rotate-me"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-10.png)"
                  }}
                />
                <div className="shape-4" />
              </div>
              <figure className="image">
                <img src="assets/images/team/sunil.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-9 col-sm-12 content-column">
          <div className="content_block_2">
            <div className="content-box">
              <div className="sec-title">
              
                <h2>Mr. Sunil Agarwal

                </h2>
                <ul className="info clearfix">
                <li>
                  <i className="icon-52" />
                  Email: <a href="mailto:sunil@radheybiotech.com">sunil@radheybiotech.com</a>
                </li>
              </ul>
                <p>I honored to introduce Radhey Biotech Private Limited, a pioneering enterprise dedicated to the development and supply of superior Active Pharmaceutical Ingredients (APIs). As the director of this groundbreaking company, I bring unique perspectives and extensive expertise, unified by a common goal: to transform healthcare through innovation and excellence in API production. Our mission is to revolutionize the pharmaceutical industry by providing innovative, reliable, and effective APIs that serve as the foundation for life-saving medications. At Radhey Biotech, we are committed to excellence in every aspect of our operations. Our state-of-the-art research and development facilities are equipped with the latest technologies, enabling us to push the boundaries of pharmaceutical innovation. Our team of experts, with decades of combined experience, works tirelessly to ensure that every product we create meets the highest standards of quality and safety. 

                </p>

             
            
              </div>
           
          
            </div>
          </div>
        </div>

        <div className='col-lg-12'>
        <p>
                At Radhey Biotech, we are committed to building strong, lasting relationships with our clients, partners, and stakeholders. By working together, we can achieve our shared goal of improving patient outcomes and advancing global healthcare. </p>
                <p>
As we embark on this exciting journey, we are driven by our passion for making a positive impact on the world. We invite you to join us in our mission to create a healthier future through the power of exceptional APIs. </p>
<p>
Thank you for visiting our website. We look forward to the opportunity to collaborate with you and make a difference in the lives of patients around the world.



                </p>
        </div>
      </div>
    </div>
  </div>
</section>

<div><Footer /></div>
  </div>
  );
}

export default Director;
