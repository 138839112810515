import Footer from "./Common/Footer";
import Header from "./Common/Header";
import React, { useState, useEffect } from "react";
function Career() {
    return (
    <div className="boxed_wrapper about-page">
   <div><Header/></div>

    <section
      className="page-title centred"
      style={{ backgroundImage: "url(assets/images/background/page-title.jpg)" }}
    >
      <div className="auto-container">
        <div className="content-box">
          <div
            className="shape"
            style={{ backgroundImage: "url(assets/images/shape/shape-63.png)" }}
          />
          <div className="title">
            <h1>Career</h1>
          </div>
        
        </div>
      </div>
    </section>
    {/* End Page Title */}
   
    <section className="about-style-two sec-pad">
  <div className="auto-container">
    <div className="row align-items-center clearfix">
      <div className="col-lg-6 col-md-12 col-sm-12 image-column">
        <div className="image_block_3">
          <div className="image-box">
            <div className="shape">
              <div
                className="shape-1 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-18.png)"
                }}
              />
              <div
                className="shape-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-19.png)"
                }}
              />
              <div
                className="shape-3"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-20.png)"
                }}
              />
            </div>
            <figure className="image">
              <img src="assets/images/resource/about-2.png" alt="" />
            </figure>
          
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 content-column">
        <div className="content_block_1">
          <div className="content-box">
            <div className="sec-title">
             
              <h2>Our Human Resource Values</h2>
            </div>
            <div className="bold-text">
              <p>
              We cherish human values. They are the most important asset for Radhey Biotech Private Limited
              and key to our success and growth.
              </p>
              <p>
              We value and trust our people and believe that continuous investment in development of Radhey
Biotech employees is most important. We believe and practice participative style of management
and the organization thrives on Performance coupled with integrity, loyalty and commitment.
Transparency across the organization forms the basis of our communication. Objectivity and
fairness are the key criteria for performance evaluation. Creativity and new ideas are encouraged,
rewarded and change is welcomed. A career at Radhey Biotech means an opportunity for ample
learning and growth. It offers avenue to work across the globe alongside the finest minds. The
company always strives to offer challenging assignments, international work, environment,
professional management, one of the best salaries and perks augmented by performance linked
rewards and incentives. We invite professionals to explore career opportunities with us.
              </p>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

   

 <div><Footer /></div>
  </div>
  
  );
}

export default Career;
