import logo from './logo.svg';
import './App.css';
import Footer from "./Common/Footer";
import Header from "./Common/Header";

function Privacy() {
  return (
 
    <>   <div className="boxed_wrapper about-page">
   <div><Header/></div>
    <section
    className="page-title centred"
    style={{ backgroundImage: "url(assets/images/background/page-title.jpg)" }}
  >
    <div className="auto-container">
      <div className="content-box">
        <div
          className="shape"
          style={{ backgroundImage: "url(assets/images/shape/shape-63.png)" }}
        />
        <div className="title">
          <h1>Privacy Policy
          </h1>
        </div>
      
      </div>
    </div>
  </section>
  {/* End Page Title */}
  {/* sidebar-page-container */}
  <section className="sidebar-page-container blog-details sec-pad-2">
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 content-side">
          <div className="blog-details-content">
           
            <div className="content-one">
             
              <div className="text">
                <p>Your privacy is important to us. As part of our efforts to facilitate and make your experience with Radhey Biotech Private Limited, Udaipur Website as user-friendly and enjoyable as possible, we may collect and use certain information you provide to us. This Privacy Policy addresses the collection, use, and disclosure of information that Radhey Biotech may gather during your use of the Website. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in the WEBSITE TERMS OF USE 
                </p>
               
                <p>Please review the entire policy to learn the types of End User information Radhey Biotech gathers, how Radhey Biotech uses that End User information, what End User information is disclosed and to what third parties, and how Radhey Biotech safeguards your End User information.
                </p>
                <p>Please also check back periodically as this Privacy Policy may change from time to time. We will notify you of any material changes by publishing the new Privacy Policy on the Website. The End User information Radhey Biotech gathers is and will be subject to the Privacy Policy in effect at the time the information is gathered. Should you have any questions or concerns at any time, please feel free to contact us.
                </p>
                <p>This Privacy Policy applies only to the Radhey Biotech Website. This Privacy Policy does not apply to any websites managed, maintained, and/or hosted by third parties not directly affiliated with Radhey Biotech that you may visit, e.g., via a link provided through the Website or on a website maintained by any third party. Radhey Biotech does not control the privacy policies of other websites to which we may provide hyperlinks. We encourage you to learn more about the online privacy and data security policies of third party websites directly from such third parties, as their policies may differ from ours.
                </p>
                
                <p>By submitting your information to us and by using the Website, Radhey Biotech will assume that you have read, understood, and consent to the Agreement, which includes this Privacy Policy, the Website Terms of Use, and all other applicable Radhey Biotech rules and policies. We also assume that you consent to Radhey Biotech use of your information as described in the Agreement. If you do not consent, you should not access or use the Website.
                </p>
             

<h4>Information Radhey Biotech Collects</h4>

              
              <h6><b>Personal Information and Non-Identifying Information</b></h6>
                <ul className="list-item clearfix">
                  <li>We may ask you for personally identifiable information if, for example, you contact us requesting information or a demonstration of our services.  This refers to information about you that can be used to contact or identify you (“Personal Information”).  Personal Information includes, but is not limited to, your full name, email address, and any other information that could be used to personally identify you.
                  </li>
                  <li>We may also collect other information that does not identify you (“Non-Identifying Information”).  Non-Identifying Information includes, but is not limited to, your zip code (on its own). Certain Non-Identifying Information would be considered a part of your Personal Information if it were combined with other identifiers (for example, combining your zip code with your street address) in a way that enables you to be identified.  But the same pieces of information are considered Non-Identifying Information when they are taken alone or combined only with other non-identifying information (for example, your gender only or your viewing preferences).
                  </li>
                  <li>We use your Personal Information (in some cases, in conjunction with your Non-Identifying Information) mainly to provide the Website and respond to correspondence from you.
                  </li>
                </ul>


                <h6><b>Usage Data</b></h6>
                <ul className="list-item clearfix">
                  <li>When you visit the Website, our servers automatically record information that your browser sends whenever you visit a website.  The information sent automatically by your browser is referred to as “Usage Data.”  This Usage Data may include information such as the manufacturer and model of your device; your Internet Service Provider (ISP); your device’s Internet Protocol (“IP”) address (or other device identifier), browser type, and operating system; referring/exit pages; clickstream data; Website access times and dates, pages of the Website that you visit, the time spent on those pages, and information you search for on the Website; and other statistics.
                  </li>
                  <li>Usage Data also includes geolocation data.  The Website collects and uses geolocation data to provide the Website.
                  </li>
                  <li>We use this information for technical administration of the Website, including to monitor and analyze use of the Website, to increase the functionality and user-friendliness of the Website, and to better tailor the Website to End Users’ needs.
                  </li>
                  <li>Usage Data may be non-identifying or it may be associated with you.  Whenever we associate Usage Data with Personal Information, we will treat it as Personal Information.
                  </li>
                </ul>

                <h6><b>Collection of Information
</b></h6>
                <ul className="list-item clearfix">
                  <li>Radhey Biotech may use a number of different techniques and technologies to collect Personal Information, Non-Identifying Information, and Usage Data.  For example, Radhey Biotech may use cookies, log files, clear gifs, pixel tags, embedded scripts, and/or other technology used to monitor your use of the Website.
                  </li>
               
                </ul>


                <h6><b>Forms
</b></h6>
                <ul className="list-item clearfix">
                  <li>We request certain information from you through the use of forms when you send us correspondence through the Website.

                  </li>
               
                </ul>


                
                <h6><b>Cookies
</b></h6>
                <ul className="list-item clearfix">
                  <li>As is regular practice on many websites, the Website uses cookies and other technologies to help us understand which parts of the Website are more popular and how much time users spend there.  A cookie is a small amount of data that is sent to and stored on your device from our server.  Cookies may, for example, allow a user to enter a password less frequently or remember a user’s preferences during an online session.
                  </li>
                  <li>The Website uses cookies to determine whether an End User is active and for other business purposes.  The Website may include first-party cookies (i.e., cookies that send data directly to Radhey Biotech and agents and contractors acting on behalf of Radhey Biotech, including cookies used to monitor, analyze, and administer the Website) and third-party cookies (i.e., cookies that send data to our contractors for their commercial use).  The cookies on our Website may also track Internet activity across different websites.  Since cookies are now used as industry standard, most devices automatically accept cookies, but you can usually change your device’s settings to decline them.  If you prefer not to enable cookies, you may choose to disable them; however, please note that certain features on the Website will not be available to you once cookies are disabled.
                  </li>
                </ul>



                <h6><b>Usage Data

</b></h6>
                <ul className="list-item clearfix">
                  <li>Usage Data are collected automatically by the Website’s servers and software.  For example, because the Website automatically collects Usage Data for all End Users that visit the Website, your Website session will be tracked. You agree that we may collect and use technical data and related information, including technical information about your device, system and application software, and peripherals, to maintain and support the Website generally.
                  </li>
                </ul>


                
                <h6><b>Tracking</b></h6>
                <ul className="list-item clearfix">
                  <li>Some Web browsers may be configured to send Do Not Track signals to websites, or users may use similar mechanisms, to indicate a user’s preference that certain web technologies not be used to track the user’s online activity.  The Website does not accept or process such Do Not Track signals or similar mechanisms.

                  </li>
                </ul>



                <h4>Use and Sharing of Information by Radhey Biotech</h4>
                <p>The End User information that Radhey Biotech collects may be added to our databases and used for business purposes, including for a statistical analysis of End Users’ behavior, for product development, for content improvement, or to customize the content and layout of the Website.
                </p>
                <p>Radhey Biotech policy is not to share the End User information it collects with third parties except under the following circumstances:
                </p>
              
<h6><b>Business Partners and Vendors</b></h6>
  <ul className="list-item clearfix">
    <li>On occasion, Radhey Biotech may utilize a third-party recruiting service.  If you have contact us through the Website for employment purposes, your information may be shared with such service.  Additionally, if you have contacted us with a product complaint or adverse event, your information may be shared with a third-party service provider and/or government agency, as required by applicable law.
    </li>
  </ul>

             
<h6><b>Business Transfers
</b></h6>
  <ul className="list-item clearfix">
    <li>As we continue to develop our business, we may buy, sell, or share assets in connection with, for example, a merger, acquisition, reorganization, sale of assets, or bankruptcy.  In such transactions, information about End Users generally is often a transferred business asset.  In the event that Radhey Biotech itself or substantially all of Radhey Biotech assets are acquired, information about our End Users may be one of the transferred assets.
    </li>
  </ul>

             
<h6><b>Compliance with Law and Protection of Radhey Biotech and Others
</b></h6>
  <ul className="list-item clearfix">
    <li>We may release End User information when we believe, in our sole discretion, that release is appropriate: to comply with the law, including but not limited to, in response to a subpoena served on Radhey Biotech; to enforce or apply the Agreement, including the Website Terms of Use and other applicable agreements, rules, and policies; to protect the rights, property, or safety of Radhey Biotech, our End Users, or others; or to prevent activity that we believe, in our sole discretion, may be or may become illegal, unethical, or legally actionable (including exchanging End User information with other companies and organizations for fraud protection).
    </li>
  </ul>




             
  <h6><b>Security
</b></h6>
  <ul className="list-item clearfix">
    <li>We employ administrative, physical, and electronic measures designed to protect your information from unauthorized access.  For example, we use commercially reasonable security measures such as encryption, firewalls, and secure socket layers (SSL) to protect End User information.
    </li>
    <li>Please note that no security system is impenetrable. Accordingly, we do not guarantee the security of our databases, nor that information you supply won’t be intercepted while being transmitted to us over the Internet or other network.  Any information you transmit to Radhey Biotech, you do at your own risk.  We will make any legally required disclosures of any breach of the security, confidentiality, or integrity of your unencrypted electronically stored “personal data” (as defined in applicable state statutes on security breach notification) to you via email or conspicuous posting through the Website in the most expedient time possible and without unreasonable delay, as consistent with (i) the legitimate needs of law enforcement or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of the data system.
    </li>
  </ul>


            
  <h6><b>Children’s Privacy</b></h6>
  <ul className="list-item clearfix">
    <li>We are committed to protecting the privacy of children.  End Users must be at least thirteen (13) years of age to access and use the Website.  Any access to or use of the Website by anyone under the age of thirteen (13) is unauthorized, unlicensed, and in violation of the Agreement.  By using the Website, you represent and warrant that you are thirteen (13) years of age or older and that you agree to and agree to abide by all of the terms and conditions of the Agreement.  If Radhey Biotech believes that you are under the age of thirteen (13) or that you are not old enough to consent to and be legally bound by the Agreement, Radhey Biotech may, at any time, in its sole discretion, and with or without notice: (i) terminate your access to or use of the Website (or any portion, aspect, or feature of them), or (ii) delete any content or information that you have posted through the Website.
    </li>
  </ul>


  
           <h4>Right to Request/Review Personal Information
           </h4> 
           <p>Upon verification of your identity you may:
           </p>
  <p><b>No more than twice in any twelve (12)-month period, request disclosure of the following information:
  </b></p>
  <ul className="list-item clearfix">
    <li>Categories of Personal Information we collect.
    </li>
    <li>Categories of sources from which Personal Information is collected.
    </li>
    <li>Business or commercial purposes for collecting, selling or sharing Personal Information.
    </li>
    <li>Categories of Personal Information sold or disclosed to third parties.
    </li>
    <li>Categories of third parties with whom such Personal Information is disclosed.
    </li>
    <li>Specific pieces of Personal Information we collect.
    </li>
  </ul>

  <p>Request to correct inaccurate Personal Information.
  </p>
<p>Request deletion of your Personal Information, subject to the exceptions provided by law and this Policy.
</p>
<p>Opt-out from having your Personal Information sold to or shared with third parties, subject to the restrictions of this Policy.
</p>
<p>Limit use and disclosure of Sensitive Personal Information for any purpose other than the pre-approved business purposes identified in applicable privacy law.  Our use of Sensitive Personal Information is already limited to the approved business purposes identified above.
</p>
<p>Requests can be submitted by contacting IT@RadheyBiotech.com .  Only you, or a person registered with the state in which you reside that you have authorized to act on your behalf, may make a verifiable consumer request related to your Personal Information. When using an authorized agent you must: (i) provide the agent with signed permission clearly describing their authority to make a request on your behalf; (ii) verify your own identity; and (iii) directly confirm that you have provided the authorized agent permission to submit the request. That agent must also be able to verify their identity with us and provide us with their authority to act on your behalf.
</p>
<p>The verifiable request initiated by you or your authorized agent must:
</p>

<ul className="list-item clearfix">
    <li>Include your full legal name, email, and phone number, which we will need to contact you in order to verify that you are the person about whom we collected Personal Information or an authorized representative.
    </li>
    <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
    </li>
  </ul>




<p>We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm the Personal Information relates to you. One of our representatives will contact you in order to verify your identity. You may need to provide additional information in order to verify your request. Depending on the nature of the request, we may require additional verification actions be taken, including but not limited to providing a signed declaration under penalty of perjury that you are the person whose Personal Information is the subject of the request. We will only use this information to verify the requestor’s identity or authority to make the request. Making a verifiable request does not require you to create any sort of additional account with us.
</p>
<p>We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:
</p>

<ul className="list-item clearfix">
    <li>Engage in employment related activities and take actions reasonably anticipated within the context of our employment relationship with you.
    </li>
    <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.
    </li>
    <li>Exercise a right provided for by law.
    </li>
    <li>Comply with state or federal law.
    </li>
    <li>Enable solely internal uses that are reasonably aligned with expectations based on your employment relationship with us.
    </li>
    <li>Comply with a legal obligation.
    </li>
    <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.
    </li>
  </ul>





<p>We endeavor to respond to a verifiable request within forty-five (45) days of its receipt. If we require more time (up to ninety (90) days), we will inform you of the reason and extension period in writing. We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded.
</p>
<p>You have the right to be free from discrimination if you choose to exercise your rights under the statute, and we will not retaliate against you for the exercise of your rights.
</p>

         
<h6><b>Changes to the Policy</b></h6>
  <ul className="list-item clearfix">
    <li>We may modify or update this Policy from time to time. We encourage you to revisit this page often to remain fully informed of our Policy or you can contact us at any time to obtain the latest copy of this Policy.
    </li>
  </ul>

      
<h6><b>Contacting Us
</b></h6>
  <ul className="list-item clearfix">
    <li>If you have any questions about this Privacy Policy, please contact us at IT@RadheyBiotech.com
    </li>
  </ul>




              </div>
            </div>
         
          </div>
        </div>
       
      </div>
    </div>
  </section>

<div><Footer /></div>
 </div>
  </>
  );
}

export default Privacy;
