import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function Login() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const navigate = useNavigate();

  const handleLogin = async (e) => {
  //   e.preventDefault();
  //   try {
  //     let { data, error } = await supabase.auth.signInWithPassword({
  //       email: email,//'info@radheybiotech.com',
  //       password: password,//'123456'
  //     })
  //     if(data){
  //       localStorage.setItem("LoginData",JSON.stringify(data));
  //       navigate('/leads');
  //     }
  //     console.log("user", { data, error });
  //   } catch (error) {
  //     console.error("Error logging in:",);
  //   }
  };

  // window.onpopstate = () => {
  //   navigate("/");
  // }
  return (
    <>
      {/* contact-style-two */}
      <section className="contact-style-two sec-pad bg-color-1 login">
        <div className="auto-container">
          <div className="sec-title centred">
            <img src="assets/images/logo.png" alt="" title className='logo' style={{ height: 55, marginBottom: 20 }} />
            <h2 style={{ fontSize: 25 }}>Login</h2>
          </div>
          <div className="form-inner">
            <form
              id="contact-form"
              className="default-form" onSubmit={handleLogin}>

              <div className="row clearfix">

                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <input type="text" name="email" required="" placeholder='Email' value={email}
                    onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <input
                    type="password"
                    name="password"
                    required=""
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                  <button
                    className="theme-btn-one"
                    type="submit"
                    name="login-form"
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
