import Footer from "./Common/Footer";
import Header from "./Common/Header";

function Mission() {
  return (
    <div className="boxed_wrapper about-page">
   <div><Header/></div>

    <section
      className="page-title centred"
      style={{ backgroundImage: "url(assets/images/background/page-title.jpg)" }}
    >
      <div className="auto-container">
        <div className="content-box">
          <div
            className="shape"
            style={{ backgroundImage: "url(assets/images/shape/shape-63.png)" }}
          />
          <div className="title">
            <h1>Mission, Vision and Core Values
            </h1>
          </div>
        
        </div>
      </div>
    </section>
    {/* End Page Title */}

    <section className="service-style-two sec-pad centred bg-color-1">
  <div className="auto-container">
   
    <div className="row clearfix">
      <div className="col-lg-6 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="00ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/service/service-1.jpg" alt="" />
            </figure>
            <div className="lower-content">
             
              <h4>Mission Statement</h4>
              <p>
              At Radhey Biotech Private Limited, our mission is to advance global
healthcare by providing high-quality Active Pharmaceutical Ingredients (APIs) that enable
pharmaceutical manufacturers to develop safe, effective, and affordable medications for patients
worldwide. We are committed to innovation, reliability, and sustainability in every aspect of our
operations.
              </p>
            
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="300ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/service/service-2.jpg" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>Vision</h4>
              <p>
              Our vision is to be a leading supplier of APIs globally, recognized for our
excellence in quality, customer satisfaction, and contribution to advancing healthcare outcomes.
We aim to foster partnerships that drive innovation, enhance patient care, and create lasting value
for our stakeholders.

              </p>
         
            </div>
          </div>
        </div>
      </div>
   
    </div>

  </div>
</section>
    

   
  {/* awards-section */}
  <section className="awards-section sec-pad-2">
    <div className="shape-layer">
      <div
        className="shape-1 rotate-me"
        style={{ backgroundImage: "url(assets/images/shape/shape-58.png)" }}
      />
      <div className="shape-2" />
    </div>
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 title-column">
          <div className="sec-title">
            
            <h2 style={{textAlign:'center'}}>Core Values</h2>
         
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 inner-column">
          <div className="inner-box mds">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 title-column">
                        <div className="single-item">
                            <h3>
                            <div className="icon-box">
                                    <i className="icon-40" />
                                </div>
                            </h3>
                            <h4>Quality</h4>
                            <p> We uphold the highest standards of quality in everything we do, ensuring the
                            safety, efficacy, and reliability of our products.</p>
                        </div>
                    </div> 
                    <div className="col-lg-6 col-md-12 col-sm-12 title-column">
                        <div className="single-item">
                            <h3>
                            <div className="icon-box">
                                    <i className="icon-38" />
                                </div>
                            </h3>
                            <h4>Integrity</h4>
                            <p> We conduct our business with honesty, transparency, and ethical practices,
                            building trust with our customers, partners, and communities.</p>
                        </div>
                    </div> 
                    <div className="col-lg-6 col-md-12 col-sm-12 title-column">
                        <div className="single-item">
                            <h3>
                            <div className="icon-box">
                                    <i className="icon-35" />
                                </div>
                            </h3>
                            <h4>Innovation</h4>
                            <p>We continuously innovate and invest in research and development to deliver
                            cutting-edge solutions that address healthcare challenges and drive industry progress.</p>
                        </div>
                    </div> 
                    <div className="col-lg-6 col-md-12 col-sm-12 title-column">
                        <div className="single-item">
                            <h3>
                            <div className="icon-box">
                                    <i className="icon-42" />
                                </div>
                            </h3>
                            <h4>Customer Focus</h4>
                            <p>We are committed to understanding and meeting the needs of our
customers, providing responsive service, and delivering tailored solutions that exceed
expectations.</p>
                        </div>
                    </div> 
                    <div className="col-lg-6 col-md-12 col-sm-12 title-column">
                        <div className="single-item">
                            <h3>
                            <div className="icon-box">
                                    <i className="icon-44" />
                                </div>
                            </h3>
                            <h4>Sustainability</h4>
                            <p>We are dedicated to environmental stewardship, minimizing our
                            ecological footprint through sustainable practices and responsible resource management.</p>
                        </div>
                    </div> 
                    <div className="col-lg-6 col-md-12 col-sm-12 title-column">
                        <div className="single-item">
                            <h3>
                            <div className="icon-box">
                                    <i className="icon-50" />
                                </div>
                            </h3>
                            <h4>Teamwork</h4>
                            <p>We value collaboration, diversity, and respect for individuals, fostering a
                            supportive environment where employees can thrive and contribute to our shared success.</p>
                        </div>
                    </div> 



                </div>
          

       

            

          </div>
        </div>
      </div>
    </div>
  </section>
  {/* awards-section end */}

   

 <div><Footer /></div>
  </div>
  
  );
}

export default Mission;
