import Footer from "./Common/Footer";
import Header from "./Common/Header";


function Products() {
  return (
    <div className="boxed_wrapper products">
 
 <div><Header/></div>
 
 <>
  {/* Page Title */}
  <section
    className="page-title centred"
    style={{ backgroundImage: "url(assets/images/background/page-title.jpg)" }}
  >
    <div className="auto-container">
      <div className="content-box">
        <div
          className="shape"
          style={{ backgroundImage: "url(assets/images/shape/shape-63.png)" }}
        />
        <div className="title">
          <h1>Products</h1>
        </div>
       
      </div>
    </div>
  </section>
  {/* End Page Title */}
  

  <section className="service-style-two sec-pad centred">
  <div className="auto-container">
    <div className="sec-title centred">
{/*    
      <h2 style={{marginBottom:20}}>Our Products</h2> */}
      
<p style={{marginBottom:20, textAlign:'center'}}>At Radhey Biotech, our beta-lactam product portfolio represents our commitment to excellence in the pharmaceutical industry. We offer a diverse selection of beta-lactam Active Pharmaceutical Ingredients (APIs) and finished goods intermediates, meticulously crafted to support the development and production of high-quality pharmaceutical products. At Radhey Biotech Private Limited, each product is meticulously developed and manufactured to the highest quality standards, supported by our commitment to innovation and excellence in API production.
</p>

<p style={{marginBottom:20, textAlign:'center'}}>Our range of beta-lactam APIs includes essential compounds used in the creation of effective antibiotics. Each API in our portfolio is produced under stringent quality control conditions to ensure unmatched purity, potency, and reliability.</p>

<p style={{marginBottom:20, textAlign:'center'}}>We also provide high-quality finished goods intermediates for beta-lactam applications, designed to streamline your manufacturing workflows. These intermediates are engineered to meet rigorous industry standards, ensuring optimal performance and regulatory compliance. Our portfolio includes comprehensive details on product.
</p>

<p style={{marginBottom:20, textAlign:'center'}}>Radhey Biotech Private Limited manufactures premium below APIs and Finished goods intermediates with a focus on quality and safety, adhering to international pharmacopeial standards and regulatory guidelines. Our APIs and Finished goods intermediates are trusted by pharmaceutical manufacturers worldwide to provide effective antibiotic treatments for diverse healthcare needs.
</p>

<p style={{marginBottom:20, textAlign:'center'}}>Explore our portfolio to find the right solutions for your needs. Should you have any questions or require further assistance, our dedicated team is here to support you.
Keep this statement after the product profile. 
</p>



    </div>
    <div className="row clearfix">
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="00ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
              <img src="assets/images/formula/Cefixime-trihydrate.png" alt="" />
            </figure>
            <div className="lower-content">
           
              <h4>Cefixime Trihydrate</h4>
              <p>Cefixime Trihydrate is a potent third-generation cephalosporin antibiotic known for its
broad-spectrum activity against gram-positive and gram-negative bacteria. It is
commonly used to treat respiratory tract infections, urinary tract infections, otitis media,
and other bacterial infections. </p>
              
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="300ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/Cefdinir.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>Cefdinir</h4>
              <p>
              Cefdinir is a third-generation cephalosporin antibiotic effective against a wide range of
bacterial infections, including Streptococcus pneumoniae, Haemophilus influenzae, and
Moraxella catarrhalis. It is indicated for the treatment of respiratory tract infections, skin
infections, and acute otitis media.
              </p>
          
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/Cefpodoxime-Proxetil.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>Cefpodoxime Proxetil </h4>
              <p>Cefpodoxime Proxetil is a third-generation cephalosporin antibiotic known for its
efficacy against a variety of bacterial infections, including respiratory tract infections,
urinary tract infections, and skin infections.
</p>
             

            </div>
          </div>
        </div>
      </div>

      
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/Cefuroxime-Axetil.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>Cefuroxime Axetil</h4>
              <p>Cefuroxime Axetil is a second-generation cephalosporin antibiotic used to treat infections
caused by susceptible bacteria, including respiratory tract infections, skin infections, and
Lyme disease.
</p>
             

            </div>
          </div>
        </div>
      </div>

      
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/Cefditoren-pivoxil.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>Cefditoren Pivoxil</h4>
              <p>Cefditoren Pivoxil is a third-generation cephalosporin antibiotic effective against a broad
              spectrum of bacterial infections, including respiratory tract infections and skin infections.
</p>
             

            </div>
          </div>
        </div>
      </div>

      
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/Cefcapene-pivoxill.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>Cefcapene Pivoxil</h4>
              <p>Cefcapene Pivoxil is a cephalosporin antibiotic indicated for the treatment of respiratory
              tract infections and urinary tract infections.
</p>
             

            </div>
          </div>
        </div>
      </div>

      
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/Cefprozil.png" alt="" />
            </figure>
            <div className="lower-content">
             
              <h4>Cefprozil</h4>
              <p>Cefprozil is a second-generation cephalosporin antibiotic used to treat various bacterial
infections, including respiratory tract infections and skin infections.

</p>
             

            </div>
          </div>
        </div>
      </div>

       
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/Ceftibuten.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>Ceftibuten</h4>
              <p>Ceftibuten is a third-generation cephalosporin antibiotic effective against a range of
              bacterial infections, including respiratory tract infections and urinary tract infections.

</p>
             

            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/Cefaclor.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>Cefaclor</h4>
              <p>Cefaclor is a second-generation cephalosporin antibiotic used to treat infections caused
by susceptible bacteria, including respiratory tract infections, urinary tract infections, and
skin infections.
</p>
             

            </div>
          </div>
        </div>
      </div>

     

      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/finished-goods/MLER.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>CEFIXIME METHYL ESTER</h4>
              <p>Chemical Name: (6R,7R)-7-[[(2Z)-2-(2-amino-1,3- thiazol-4-yl)-2-(2-methoxy-2-
oxoethoxy)iminoacetyl]amino]-3- ethenyl-8-oxo-5-thia-1- azabicyclo[4.2.0]oct-2-ene-2-
carboxylic acid</p>
              <p>API: CEFIXIME</p>
             

            </div>
          </div>
        </div>
      </div>



      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/finished-goods/CEFUROXIME-ACID.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>CEFUROXIME ACID</h4>
              <p>Chemical Name: (6R,7R)-3-(carbamoyloxymethyl)-7- [[(2Z)-2-(furan-2-yl)-2-
methoxyiminoacetyl]amino]-8-oxo-5- thia-1-azabicyclo[4.2.0]oct-2-ene-2- carboxylic
acid
</p>
              <p>API: CEFUROXIME AXETIL</p>
             

            </div>
          </div>
        </div>
      </div>

      
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/finished-goods/cefuroxime-axetil-crystalline.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>CEFUROXIME AXETIL (CRYSTALLINE)</h4>
              <p>Chemical Name: 1-acetyloxyethyl (6R,7R)-3- (carbamoyloxymethyl)-7-[[(2Z)-2- (furan2-yl)-2- methoxyiminoacetyl]amino]-8-oxo-5- thia-1-azabicyclo[4.2.0]oct-2-ene-2-
              carboxylate</p>
              <p>API: CEFUROXIME AXETIL (AMORPHOUS)</p>
             

            </div>
          </div>
        </div>
      </div>
    
   
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/finished-goods/GCTA.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>GCTA</h4>
              <p>Chemical Name: (6R,7R)-4-methoxybenzyl 3-((Z)-2-(4- methylthiazol-5-yl)vinyl)-8-
oxo-7-(2- phenylacetamido)-5-thia-1-azabicyclo[4.2.0]oct-3- ene-2-carboxylate
</p>
              <p>API: CEFDITOREN PIVOXIL</p>
             

            </div>
          </div>
        </div>
      </div>


      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/finished-goods/GCTA.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>7-ATCA</h4>
              <p>Chemical Name: (6R,7R)-7-Amino-3-[(1Z)-2-(4- methyl-5-thiazolyl)ethenyl]-8-oxo-5-
              thia-1-azabicyclo[4.2.0]oct-2-ene-2- carboxylic acid
</p>
              <p>API: CEFDITOREN PIVOXIL
              </p>
             

            </div>
          </div>
        </div>
      </div>

   
      <div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/finished-goods/Cefditoren-sodium.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>CEFDITOREN SODIUM</h4>
              <p>Chemical Name: sodium (6R,7R)-7-((E)-2-(2- aminothiazol-4-yl)-2-
(methoxyimino)acetamido)-3-((Z)-2- (4-methylthiazol-5-yl)vinyl)-8-oxo-5- thia-1-
azabicyclo[4.2.0]oct-2-ene-2- carboxylate
</p>
              <p>API: CEFDITOREN PIVOXIL
              </p>
             

            </div>
          </div>
        </div>
      </div>

<div className="col-lg-4 col-md-6 col-sm-12 service-block">
  <div
    className="service-block-two wow fadeInUp animated"
    data-wow-delay="600ms"
    data-wow-duration="1500ms"
  >
    <div className="inner-box">
      <figure className="image-box">
      <img src="assets/images/formula/finished-goods/Cefpodoxime-acid.png" alt="" />
      </figure>
      <div className="lower-content">
      
        <h4>CEFPODOXIME ACID</h4>
        <p>Chemical Name: (6R,7R)-7-[[(2Z)-2-(2-amino-1,3- thiazol-4-yl)-2-
methoxyiminoacetyl]amino]-3- (methoxymethyl)-8-oxo-5-thia-1- azabicyclo[4.2.0]oct-2-
ene-2- carboxylic acid</p>
        <p>API: CEFPODOXIME PROXETIL
        </p>
       

      </div>
    </div>
  </div>
</div>


<div className="col-lg-4 col-md-6 col-sm-12 service-block">
  <div
    className="service-block-two wow fadeInUp animated"
    data-wow-delay="600ms"
    data-wow-duration="1500ms"
  >
    <div className="inner-box">
      <figure className="image-box">
      <img src="assets/images/formula/finished-goods/7APCA.png" alt="" />
      </figure>
      <div className="lower-content">
      
        <h4>7–APCA</h4>
        <p>Chemical Name: (6R,7R) - 7 -Amino - 8 -oxo - 3 -(1 -propen - 1 -yl) - 5 -thia - 1 -
azabicyclo[4.2.0]oct - 2 - ene - 2 -carboxylic Acid; (6R -trans) - 7 - Amino - 8 -oxo - 3 -
(1 -propenyl) - 5 -Thia - 1 -azabicyclo[4.2.0]oct - 2 -ene - 2 - carboxylic acid
</p>
        <p>API: CEFPROZIL </p>
       

      </div>
    </div>
  </div>
</div>



<div className="col-lg-4 col-md-6 col-sm-12 service-block">
  <div
    className="service-block-two wow fadeInUp animated"
    data-wow-delay="600ms"
    data-wow-duration="1500ms"
  >
    <div className="inner-box">
      <figure className="image-box">
      <img src="assets/images/formula/finished-goods/7ANCE.png" alt="" />
      </figure>
      <div className="lower-content">
      
        <h4>7-ANCE</h4>
        <p>Chemical Name: (6R,7R)-7-amino-8-oxo-5-thia-1- azabicyclo[4.2.0]oct-2-ene-2-
carboxylic acid
</p>
        <p>API: CEFTIBUTEN </p>
       

      </div>
    </div>
  </div>
</div>
<div className="col-lg-4 col-md-6 col-sm-12 service-block">
        <div
          className="service-block-two wow fadeInUp animated"
          data-wow-delay="600ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className="image-box">
            <img src="assets/images/formula/finished-goods/7AVCA.png" alt="" />
            </figure>
            <div className="lower-content">
            
              <h4>7-AVCA</h4>
              <p>Chemical Name: (6R,7R)-7-Amino-8-oxo-3-vinyl-5- thia-1-azabicyclo[4.2.0]oct-2-ene2- carboxylic acid</p>
              <p>API: CEFIXIME</p>
             

            </div>
          </div>
        </div>
      </div>




    </div>
  
  </div>
</section>

  {/* service-section end */}
</>

 
    <div><Footer/></div>
  </div>
  
  );
}

export default Products;
